/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.App {
  padding: 0 !important;
  overflow-x: hidden;
}

@media (max-width: 400px) {
  .popup-content {
    width: 90% !important;
  }
}

.rc-upload {
  display: flex;
  justify-content: center;
}

/* @media (max-width: 550px) {
  .popup-content {
    width: 70% !important;
  }
} */
/* .fancy-text {
  font-family: "Fjalla One", sans-serif;
  text-shadow: #c09a9a 1px 1px, #533d4a 2px 2px, #fea204 3px 3px,
    #ce2021 5px 5px;
  letter-spacing: 5px;
} */
