/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.scrollbar::-webkit-scrollbar {
  width: 0;
}

.scrollbar:hover::-webkit-scrollbar {
  width: 3px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #e5e7eb;
  border-radius: 10px;
}

/* make scrollbar transparent on Firefox, IE and Edge*/
.scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#zmmtg-root {
  display: none;
}

.hide-file-item-name .file-item-full-container-container .file-item-name {
  display: none;
}
